/* eslint-disable only-ascii/only-ascii */
const translations = {
    'en': {
        'message.inReplyTo': 'in reply to',
        'message.reply': 'Reply',
        'message.likeThisMessage?': 'Recommend this message?',
        'postForm.pleaseInputMessageText': 'Please input message text',
        'postForm.upload': 'Upload',
        'postForm.newMessage': 'New message...',
        'postForm.edit': 'Edit message...',
        'postForm.imageLink': 'Link to image',
        'postForm.imageFormats': 'JPG/PNG, up to 100 MB',
        'postForm.or': 'or',
        'postForm.tags': 'Tags (space separated)',
        'postForm.submit': 'Send',
        'comment.writeComment': 'Write a comment...',
        'shareDialog.linkToMessage': 'Link to message',
        'shareDialog.messageNumber': 'Message number',
        'shareDialog.share': 'Share',
        'loginDialog.pleaseIntroduceYourself': 'Please introduce yourself',
        'loginDialog.registeredAlready': 'Registered already?',
        'loginDialog.username': 'Username',
        'loginDialog.password': 'Password',
        'loginDialog.facebook': 'Login with Facebook',
        'loginDialog.vk': 'Login with VK',
        'loginDialog.email': 'Registration',
        'error.error': 'Error',
        'settings.notifications.granted': 'Enabled',
        'settings.notifications.denied': 'Denied',
        'settings.notifications.request': 'Enable',
        'link.discuss': 'Discuss',
        'link.allMessages': 'Discover',
        'link.postMessage': 'Post',
        'link.Login': 'Login',
        'link.contacts': 'Contacts',
        'link.tos': 'TOS',
        'link.privacy': 'Privacy',
        'link.settings': 'Settings',
        'link.settings.main': 'Main',
        'link.settings.password': 'Password',
        'link.settings.about': 'About',
        'link.logout': 'Logout',
        'label.sponsors': 'Sponsors',
        'label.search': 'Search',
        'date.format': 'MM/dd/yyyy',
        'link.returnToMain': 'Back to Home Page',
        'link.adv': 'Advertisement',
        'link.popular': 'Popular',
        'link.withPhotos': 'Photos',
        'link.trends': 'Trends',
        'link.my': 'My feed',
        'link.privateMessages': 'PM',
        'link.recommended': 'Recommended',
        'label.sponsor': 'Sponsor',
        'label.register': 'Register with',
        'label.username': 'User name',
        'label.password': 'Password',
        'message.recommend': 'Recommend',
        'message.recommendedBy': '♡ recommended by',
        'message.recommendedOthers': 'and {0} others',
        'message.comment': 'Comment',
        'message.writeComment': 'Write a comment...',
        'message.share': 'Share',
        'message.subscribe': 'Subscribe',
        'message.subscribed': 'Subscribed',
        'message.delete': 'Delete',
        'message.loginForSending': '<a href="{0}" class="a-login">Login</a> to post messages and comments',
        'message.sendLoginToXmpp': 'Send <b>LOGIN</b> to <a href="xmpp:juick@juick.com?message;body=LOGIN">juick@juick.com</a>',
        'message.lock': 'Make private',
        'message.unlock': 'Make public',
        'messages.next': 'Next',
        'reply.reply': 'Reply',
        'reply.inReplyTo': 'in reply to',
        'reply.replies': 'Replies',
        'replies.showAsList': 'Show as list',
        'replies.showAsTree': 'Show as tree',
        'replies.unfoldAll': 'Unfold all',
        'question.areRegistered': 'Already registered?',
        'title.help': 'Help',
        'title.loginOrSignup': 'Juick - Log In or Sign Up',
        'title.index.anonym': 'Juick microblogs: popular posts',
        'title.index.user': 'Popular',
        'title.search': 'Search:',
        'error.pageNotFound': 'Page not found',
        'error.pageNotFound.description': 'User probably deleted this post, or this page never existed.',
        'blog.blog': 'Blog',
        'blog.recommendations': 'Recommendations',
        'blog.photos': 'Photos',
        'blog.iread': 'I read',
        'blog.readers': 'My readers',
        'blog.bl': 'Ignored',
        'blog.messages': 'Messages',
        'blog.comments': 'Comments',
        'blog.allPostsWithTag': 'All posts tagged',
    },
    'ru': {
        'message.inReplyTo': 'в ответ на',
        'message.reply': 'Ответить',
        'message.likeThisMessage?': 'Рекомендовать это сообщение?',
        'postForm.pleaseInputMessageText': 'Пожалуйста, введите текст сообщения',
        'postForm.upload': 'загрузить',
        'postForm.newMessage': 'Новое сообщение...',
        'postForm.edit': 'Редактирование...',
        'postForm.imageLink': 'Ссылка на изображение',
        'postForm.imageFormats': 'JPG/PNG, до 100 Мб',
        'postForm.or': 'или',
        'postForm.tags': 'Теги (через пробел)',
        'postForm.submit': 'Отправить',
        'comment.writeComment': 'Написать комментарий...',
        'shareDialog.linkToMessage': 'Ссылка на сообщение',
        'shareDialog.messageNumber': 'Номер сообщения',
        'shareDialog.share': 'Поделиться',
        'loginDialog.pleaseIntroduceYourself': 'Пожалуйста, представьтесь',
        'loginDialog.registeredAlready': 'Уже зарегистрированы?',
        'loginDialog.username': 'Имя пользователя',
        'loginDialog.password': 'Пароль',
        'loginDialog.facebook': 'Войти через Facebook',
        'loginDialog.vk': 'Войти через ВКонтакте',
        'loginDialog.email': 'Регистрация',
        'error.error': 'Ошибка',
        'settings.notifications.granted': 'Подключено',
        'settings.notifications.denied': 'Запрещено',
        'settings.notifications.request': 'Подключить',
        'link.discuss': 'Диалоги',
        'link.allMessages': 'Обзор',
        'link.postMessage': 'Написать',
        'link.Login': 'Войти',
        'link.contacts': 'Контакты',
        'link.tos': 'TOS',
        'link.privacy': 'Конфиденциальность',
        'link.settings': 'Настройки',
        'link.settings.main': 'Основные',
        'link.settings.password': 'Пароль',
        'link.settings.about': 'О пользователе',
        'link.logout': 'Выйти',
        'label.sponsors': 'Спонсоры',
        'label.search': 'Поиск',
        'date.format': 'dd.MM.yyyy',
        'link.returnToMain': 'Вернуться на главную',
        'link.popular': 'Популярные',
        'link.withPhotos': 'Фото',
        'link.trends': 'Темы',
        'link.my': 'Моя лента',
        'link.privateMessages': 'Приватные',
        'link.recommended': 'Рекомендации',
        'label.sponsor': 'Спонсор',
        'label.register': 'Зарегистрироваться c',
        'label.username': 'Имя пользователя',
        'label.password': 'Пароль',
        'message.recommend': 'Рекомендовать',
        'message.recommendedBy': '♡ рекомендовали',
        'message.recommendedOthers': 'и еще {0}',
        'message.comment': 'Комментировать',
        'message.writeComment': 'Написать комментарий...',
        'message.share': 'Поделиться',
        'message.subscribe': 'Подписаться',
        'message.subscribed': 'Подписан',
        'message.delete': 'Удалить',
        'message.loginForSending': 'Чтобы добавлять сообщения и комментарии, <a href="{0}" class="a-login">представьтесь</a>',
        'message.sendLoginToXmpp': 'Отправьте <b>LOGIN</b> на <a href="xmpp:juick@juick.com?message;body=LOGIN">juick@juick.com</a>',
        'message.lock': 'Скрыть',
        'message.unlock': 'Открыть доступ',
        'messages.next': 'Читать дальше',
        'reply.reply': 'Ответить',
        'reply.inReplyTo': 'в ответ на',
        'reply.replies': 'Ответы',
        'replies.showAsList': 'Показать списком',
        'replies.showAsTree': 'Показать деревом',
        'replies.unfoldAll': 'Раскрыть все',
        'question.areRegistered': 'Уже зарегистрированы?',
        'title.help': 'Справка',
        'title.loginOrSignup': 'Juick - Войдите в систему или зарегистрируйтесь',
        'title.index.anonym': 'Микроблоги Juick: популярные записи',
        'title.index.user': 'Популярные',
        'title.search': 'Поиск:',
        'error.pageNotFound': 'Страница не найдена',
        'error.pageNotFound.description': 'Сожалеем, но страницу с этим адресом удалил её автор, либо её никогда не существовало.',
        'blog.blog': 'Блог',
        'blog.recommendations': 'Рекомендации',
        'blog.photos': 'Фотографии',
        'blog.iread': 'Я читаю',
        'blog.readers': 'Мои подписчики',
        'blog.bl': 'Игнорирую',
        'blog.messages': 'Сообщения',
        'blog.comments': 'Комментарии',
        'blog.allPostsWithTag': 'Все записи с тегом',
    }
}
/* eslint-enable only-ascii/only-ascii */

/**
 * Detect window language
 * @returns {string} Detected language
 */
function getLang() {
    return (window.navigator.languages && window.navigator.languages[0])
        || window.navigator['userLanguage']
        || window.navigator.language
}
/**
 *  translate message to lang
 * @param { string } key message
 * @param { string? } lang lang
 */
export function i18n(key = '', lang = undefined) {
    const fallbackLang = 'ru'
    lang = lang || getLang().split('-')[0]
    return (translations[lang] && translations[lang][key])
        || translations[fallbackLang][key]
        || key
}

/**
 * "timeAgo" date format
 * @param {Date} date past date
 */
export function timeAgo(date) {
    if (!('RelativeTimeFormat' in Intl)) {
        return date.toLocaleString(getLang())
    }
    const formatter = new Intl.RelativeTimeFormat(getLang(), {
        numeric: 'auto',
    })

    const now = new Date()
    const diff = date.getTime() - now.getTime()

    /** @type { Array<{ unit: Intl.RelativeTimeFormatUnit, divisor: number }> } */
    const units = [
        { unit: 'year', divisor: 31536000000 },
        { unit: 'month', divisor: 2628000000 },
        { unit: 'week', divisor: 604800000 },
        { unit: 'day', divisor: 86400000 },
        { unit: 'hour', divisor: 3600000 },
        { unit: 'minute', divisor: 60000 },
        { unit: 'second', divisor: 1000 },
    ]

    for (const { unit, divisor } of units) {
        if (Math.abs(diff) >= divisor) {
            const value = Math.round(diff / divisor)
            return formatter.format(value, unit)
        }
    }

    return formatter.format(0, 'second') // Для разницы < 1 секунды
}
